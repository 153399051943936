import React from 'react';
import Layout from '../layouts/main';
import PageBanner from '../components/pagebanner';
import SEO from '../components/seo';

const ContactUs = () => {
    return (
        <Layout>
            <SEO title="Contact Us"/>
        <PageBanner title="Contact Us" />
        <div className="section contactus">
            <div className="innerwrap">
                <div className="row">
                    <div className="section-title">
                        <span>Lets Connect</span>
                        <h2>Drop Us A Mail</h2>
                    </div>
                    <div className="grid-5 grid-sm-12 grid-xs-12">
                        <div className="address-section">
                        <h3>Our Address</h3>
                        <div className="addr"><p> Sydney, Blacktown, Bruce Street, NSW - 2148</p>
                        </div>
                        <p><b>Email: </b>savnetedu@gmail.com</p>
                        {/* <p><b>Phone: </b> +91 90000909900 </p> */}
                        </div>
                    </div>
                    <div className="grid-7 grid-sm-12 grid-xs-12">
                        <div className="maps">
                            <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Blacktown%20,%20Bruce%20Street%20,%20NSW%20-%202148%20Sydney+(Savnet%20Edu)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            
                        </div>
                        <div className="contactform">

                        </div>
                    </div>

                </div>
            </div>
        </div>
        </Layout>
    )
}

export default ContactUs;